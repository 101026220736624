@import 'Styles/Base/includes';

/**
*
* EventSidebar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSidebar {
    $root: &;

    padding: 15px 20px;
    border: 1px solid black;
    text-align: center;
    display: block;
    margin: auto;
    margin-bottom: 30px;

    @include media(md) {
        padding: 30px 35px;
        margin-bottom: 40px;
    }

    &--Horizontal {
        margin-bottom: 0 !important;
    }

    &--Footer {
        padding: 0;
        margin: 0 !important;
        color: white;
        border: none;

        svg * {
            fill: white !important;
        }
    }

    &__Title {
        font-size: 1rem;
        font-weight: $medium;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    svg {
        width: 102px;
        fill: $medborgarskolan-blue-new;

        @include media(md) {
            width: 110px;
        }
    }
}

@import 'Styles/Pahlmans/includes';

/**
*
* EventSidebar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSidebar.Base.module.scss';

.EventSidebar {
    $root: &;

    width: 220px;
    border-color: palette(black, 15);

    @include media(sm) {
        width: 250px;
        padding: 30px 35px;
        margin-bottom: 80px;
    }

    @include media(md) {
        margin-bottom: 40px;
    }

    &__Title {
        color: palette(black, 46);
        font-family: $font-base;
    }
}

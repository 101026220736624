@import 'Styles/Medborgarskolan/includes';

/**
*
* Accordion
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Accordion.Base.module';

.CookiePopupAccordion {
    $root: &;

    border-radius: 4px;
    background: $blue-10;

    &__Toggle {
        &::after,
        &::before {
            background: $blue;
        }
    }
}

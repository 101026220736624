@import 'Styles/Base/includes';

/**
*
* Table
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CookiePopupTable {
    $root: &;

    width: 100%;
    font-size: 1.2rem;
    text-align: left;
    min-width: 400px;

    @include media(xs) {
        min-width: 0;
    }

    &__Cell {
        padding: 10px 10px 10px 0;

        &--Head {
            font-weight: $bold;
        }

        &--Body {
            border-top: 1px solid rgba($link-color, .15);

            &:first-child {
                word-break: break-word;
            }
        }

        &--Text {
            padding: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__Toggle {
        @include u-button(link);
        position: relative;
        top: -4px;
        right: -8px;
        width: 28px;
        height: 34px;
        margin-top: -10px;
        margin-bottom: -12px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 12px;
            right: 8px;
            width: 12px;
            height: 10px;
            margin: auto;
            background: url('../#{$basepath}img/bg-icons/base/dropdown.svg') no-repeat center center;
            background-size: contain;
            transition: transform .3s ease-in-out;

            #{$root}__Row--Open & {
                transform: rotate(180deg);
            }
        }
    }

    &__Text {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-in-out;

        #{$root}__Row--Open & {
            max-height: 200px;
        }

        p {
            padding-bottom: 16px;
        }
    }
}

@import 'Styles/Kulturama/includes';

@import './Footer.Base.module.scss';

.Footer {
    $root: &;

    background-color: palette(black, 95);

    &__Container {
        color: white;
    }

    &__MenuSecondary {
        border-bottom: 1px solid palette(black, 45);

        @include media(sm) {
            border-bottom: 0;
        }
    }

    &__Logo {
        width: 100%;
        height: 79px;

        @include media(sm) {
            width: 156px;
            height: 156px;
        }

        img {
            width: 79px;
        }
    }
}

@import 'Styles/Pahlmans/includes';

@import './Footer.Base.module.scss';

.Footer {
    $root: &;

    background-color: $footer-color;

    &__Wrap {
        padding-top: 75px;
        padding-bottom: 75px;

        @include media(md) {
            padding-top: 75px;
            padding-bottom: 75px;
        }
    }

    &__MenuSecondary {
        margin-bottom: 20px;
        padding-bottom: 0;

        @include media(sm) {
            margin-bottom: 60px;
        }

        @include media(lg) {
            margin-bottom: 80px;
        }
    }

    &__Item {
        #{$root}__MenuSecondary & {
            @include media(sm) {
                margin-right: 20px;
            }
        }
    }

    &__Link {
        font-weight: $medium;

        #{$root}__MenuPrimary & {
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 2.4rem;
            font-family: $font-heading;
        }

        #{$root}__MenuSecondary & {
            padding-top: 10px;
            padding-bottom: 10px;

            @include media(lg) {
                font-size: 2rem;
            }
        }
    }

    &__Info {
        flex-flow: column;
        align-items: flex-start;

        @include media(sm) {
            flex-flow: row;
        }
    }

    &__Column {
        width: calc(50% - 10px);
        display: inline-block;
        position: relative;
        z-index: 1;

        @include media(xs) {
            width: auto;
        }

        @include media(sm) {
            width: auto;
        }

        @include media(md) {
            flex-basis: auto;
            min-width: 240px;
        }

        &:not(:first-child) {
            margin-left: 0px;
            margin-top: 20px;

            @include media(sm) {
                margin-top: 0px;
                margin-left: 60px;
            }

            @include media(md) {
                margin-left: 60px;
            }
        }
    }

    &__Wysiwyg {
        color: white;
        font-size: 1.6rem;

        * {
            color: white !important;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: $weight-heading;
            font-family: $font-base !important;
        }

        strong {
            font-weight: $bold;
        }
    }

    &__Logo {
        width: 152px;
        height: 202px;
        position: absolute;
        right: -12px;
        bottom:-75px;
        background: $bgImage no-repeat right center;
        background-size: contain;
        opacity: 0.3;

        @include media(xs) {
            width: 152px;
            height: 232px;
            opacity: 1;
            margin-top: 0;
            margin-right: 0;
            right: 0;
            bottom:0;
        }

        @include media(sm) {
            width: 152px;
            height: 232px;
            opacity: 1;
            right: 0;
            bottom:0;
        }

        img {
            display: none;
        }
    }
}

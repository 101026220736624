@import 'Styles/Kulturama/includes';

/**
*
* Table
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Table.Base.module';

.CookiePopupTable {
    $root: &;

    &__Cell {
        &--Body {
            border-color: palette(black, 11);
        }
    }
}

@import 'Styles/Tillskararakademin/includes';

@import './Header.Base.module.scss';

.Header {
    $root: &;

    box-shadow: 0 2px 6px 0 rgba(black, .1);

    &__Service {
        display: none;
    }

    &__Back {
        align-self: flex-start;
        line-height: 2;
        opacity: 1;

        &::before {
            background-image: url(#{$basepath}img/bg-icons/tillskararakademin/caret-left--green.svg);
            transition: transform .3s ease-in-out;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 16px;
            right: 0;
            height: 2px;
            transition: opacity ease .2s;
            background-color: palette(black, 80);
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        &:hover {
            opacity: 1;

            &::before {
                transform: translateX(-5px);
            }

            &::after {
                opacity: 1;

                @media(hover: none) {
                    opacity: 0;
                }
            }
        }
    }

    &__Main {
        :global(.MenuMobileActive) & {
            border-color: palette(black, 10);
        }
    }

    &__Logo {
        img {
            width: 63px;
            height: 63px;
        }
    }

    &__ButtonLink {
        color: palette(black, 45);
        font-weight: $medium;
        border-color: palette(black, 10);

        &:hover {
            color: palette(black);
            border-color: palette(black);
        }
    }

}

@import 'Styles/Kulturama/includes';

/**
*
* Accordion
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Accordion.Base.module';

.CookiePopupAccordion {
    $root: &;

    background: palette(black, 4);

    &__Header {
        padding-top: 15px;
        padding-bottom: 16px;
    }

    &__Title {
        font-weight: $medium;
    }
}

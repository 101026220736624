@import 'Styles/Tillskararakademin/includes';

/**
*
* Follow
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Follow.Base.module';

.Follow {
    $root: &;

    color: palette(white);
    font-family: $font-base;
    text-transform: uppercase;
    font-size: 2.6rem;
    background: palette(green);
    font-weight: $medium;
    margin-top: 45px;

    &__Wysiwyg {
        a {
            color: white;
            font-weight: $medium;

            &::after {
                background: white;
            }

            &:hover {
                &::after {
                    opacity: 0;
                }
            }
        }
    }
}

@import 'Styles/Medborgarskolan/includes';

@import './Footer.Base.module.scss';

.Footer {
    $root: &;

    color: white;
    background-color: $blue;

    &__Wrap {
        padding-top: 0 !important;

        @include media(xl) {
            padding-bottom: 60px;
        }
    }

    &__Content {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-left: -24px;
        padding-top: 48px;

        @include media(md) {
            padding-top: 64px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 24px;
            width: calc(100% - 24px);
            height: 1px;
            background: white;
            opacity: .4;
        }
    }

    &__Menu {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 auto;
        width: 100%;

        @include media(lg) {
            width: 50%;
        }
    }

    &__MenuColumn {
        width: calc(100% - 24px);
        margin-left: 24px;

        @include media(sm) {
            width: calc(50% - 24px);
        }
    }

    &__Title {
        margin-bottom: 32px;
        font-size: 2.4rem;
        font-family: $font-heading;

        @include media(md) {
            margin-bottom: 40px;
            font-size: 2.6rem;
        }
    }

    &__MenuPrimary,
    &__MenuSecondary,
    &__Links {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        padding: 0;

        @include media(lg) {
            margin-bottom: 0;
        }
    }

    &__Link {
        display: block;
        margin: 0 0 16px;
        padding: 0 !important;
        font-size: 1.8rem !important;
        font-weight: $normal;
        line-height: 1.5;

        @include media(md) {
            margin: 0 0 20px;
        }
    }

    &__Info {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        flex: 0 0 auto;
        width: 100%;
        position: relative;

        @include media(lg) {
            width: 50%;
        }
    }

    &__Column {
        flex: 0 0 auto;
        width: calc(100% - 24px);
        margin-left: 24px !important;
        margin-bottom: 32px;
        font-size: 1.6rem;

        @include media(sm) {
            width: calc(50% - 24px);
            margin-bottom: 0;
        }

        &:last-of-type {
            @include media(sm) {
                padding-bottom: 100px;
            }
        }
    }

    &__Wysiwyg {
        h2 {
            margin-bottom: 32px;
            font-size: 2.6rem;
            font-family: $font-heading;

            @include media(md) {
                margin-bottom: 40px;
            }
        }

        p {
            margin: 0 0 16px;
            font-size: 1.8rem;
            line-height: 1.5;

            @include media(md) {
                margin: 0 0 20px;
            }
        }

        a {
            &::before {
                content: '';
                display: none;
                width: 24px;
                height: 24px;
                margin: -6px 12px -6px 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }

            @include href-tel {
                &::before {
                    display: inline-block;
                    background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/phone--white.svg');
                }
            }

            @include href-mailto {
                &::before {
                    display: inline-block;
                    background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/mail--white.svg');
                }
            }
        }
    }

    &__Logo {
        width: 185px;
        height: 53px;
        margin-top: 16px;

        @include media(sm) {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 0;
        }

        @include media(md) {
            width: 252px;
            height: 72px;
        }

        svg {
            width: 100%;
            height: 100%;
            display: block;

            * {
                fill: white;
            }
        }
    }
}

@import 'Styles/Medborgarskolan/includes';

@import 'Follow.Base.module';

.Follow {
    $root: &;
    display: flex;
    flex-direction: column;
    padding: 35px 20px;
    color: white;
    font-weight: $bold;
    line-height: 1.8;
    background: $blue;

    @include media(sm) {
        padding: 53px 40px;
    }

    &--Minimal {
        padding: 20px;
        font-size: 1.6rem;

        @include media(sm) {
            padding: 40px;
            font-size: 2rem;
        }
    }

    &__Wysiwyg {
        a {
            color: white;

            &::after {
                bottom: -7px;
                background: white;

                #{$root}--Minimal & {
                    bottom: -5px;
                }
            }

            &:hover {
                color: white;
            }
        }
    }

    &__Items {
        display: inline-flex;
    }

    &__Icon {
        transition: transform $transition;

        &:hover {
            transform: scale(1.12);
        }

        &--Facebook::after {
            background-image: url("#{$basepath}img/bg-icons/medborgarskolan/new/facebook--blue.svg");
        }

        &--X::after {
            background-image: url("#{$basepath}img/bg-icons/medborgarskolan/new/twitter-x--blue.svg");
        }

        &--Linkedin::after {
            background-image: url("#{$basepath}img/bg-icons/medborgarskolan/new/linkedin--blue.svg");
        }

        &--Youtube::after {
            background-image: url("#{$basepath}img/bg-icons/medborgarskolan/new/youtube--blue.svg");
        }

        &--Instagram::after {
            background-image: url("#{$basepath}img/bg-icons/medborgarskolan/new/instagram--blue.svg");
        }
    }
}

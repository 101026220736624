@import 'Styles/Base/includes';

/**
*
* Accordion
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CookiePopupAccordion {
    $root: &;

    margin-bottom: 16px;

    &__Header {
        display: flex;
        padding: 17px 0 14px 16px;
    }

    &__Title {
        flex: 1 0 auto;
        font-size: 1.6rem;
        font-weight: $medium;
    }

    &__Toggle {
        @include u-button(link);
    }

    &__Selection {
        position: relative;
        width: 44px;
        height: 20px;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 12px;
            height: 10px;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            pointer-events: none;
            z-index: 1;
        }

        &::before {
            left: 8px;
            background-image: url('../#{$basepath}img/bg-icons/base/check--white.svg');
            opacity: 0;

            #{$root}--Active & {
                opacity: 1;
            }
        }

        &::after {
            right: 8px;
            background-image: url('../#{$basepath}img/bg-icons/base/close--white.svg');
            opacity: 1;

            #{$root}--Active & {
                opacity: 0;
            }
        }

        input {
            padding: 0 !important;
            margin: 0;
            border: none;
            position: absolute;
            opacity: 0;
        }

        label {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: $invalid-color;
            cursor: pointer;
            transition: background .3s ease-in-out;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: white;
                transition: transform .3s ease-in-out;
            }
        }

        input:checked + label {
            background: $valid-color;

            &::after {
                transform: translateX(24px);
            }
        }
    }

    &__Toggle {
        position: relative;
        width: 40px;
        height: 50px;
        margin-top: -17px;
        margin-bottom: -17px;
        margin-left: 10px;
        cursor: pointer;

        &::after,
        &::before {
            content: '';
            width: 12px;
            height: 2px;
            position: absolute;
            top: 24px;
            bottom: 18px;
            right: 18px;
            display: inline-block;
            background: $link-color;
            opacity: 1;
            transition: opacity .3s ease-in-out;
        }

        &::after {
            transform: rotate(90deg);

            #{$root}--Open & {
                opacity: 0;
            }
        }
    }

    &__Content {
        padding: 0 16px 16px;
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;
    }

    &__Table {
        width: 100%;
        margin-top: 24px;
        overflow-x: auto;

        @include media(xs) {
            overflow-x: visible;
        }

        @include media(sm) {
            margin-top: 40px;
        }
    }
}

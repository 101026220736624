@import 'Styles/Dansskolanentre/includes';

/**
*
* CookiePopup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CookiePopup.Base.module';

.CookiePopup {
    $root: &;

    &__Button {
        padding-top: 6px;
        color: $text-color;
        background: $red-5;

        &::before {
            top: 5px;
            background-image: url('#{$basepath}img/bg-icons/dansskolanentre/cookie--red.svg');
        }
    }

    &__Content {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @include media(sm) {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }

    &__Text {
        a {
            color: $link-color;

            &:hover {
                color: $link-hover-color;

                @media(hover: none) {
                    color: $link-color;
                }
            }
        }
    }

    &__Accept {
        @include u-button(solid);
    }

    &__Deny {
        @include u-button(border);

        &:hover {
            color: white;
        }
    }

    &__Submit {
        @include u-button(border);

        span {
            position: relative;
        }

        &:hover {
            color: white;
        }
    }
}
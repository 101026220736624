@import 'Styles/Dansskolanentre/includes';

/**
*
* Follow
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Follow.Base.module';

.Follow {
    $root: &;

    color: $text-color;
    background: $red-5;
    font-weight: $bold;
    font-size: 2rem;

    @include media (sm) {
        padding-top: 49px;
        padding-bottom: 49px;
        font-size: 3.2rem;
    }

    &__Wysiwyg {
        a {
            color: $text-color;

            &::after {
                bottom: 2px;
                background: $text-color;
            }

            &:hover {
                &::after {
                    opacity: 0;
                }
            }
        }
    }
}

@import 'Styles/Pahlmans/includes';

/**
*
* Follow
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Follow.Base.module';

.Follow {
    $root: &;

    font-family: $font-heading;
    font-weight: $medium;
    background: white;

    &__Wysiwyg {
        a {
            &::after {
                background: $primary-color;
            }

            &:hover {
                color: $primary-color;
            }
        }
    }
}

@import 'Styles/Dansskolanentre/includes';

/**
*
* EventSidebar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSidebar.Base.module.scss';

.EventSidebar {
    $root: &;

    margin-top: 40px;
    border-radius: 8px;
    border-color: $grey-25;

    @include media(sm) {
        border-radius: 12px;
    }

    &--Footer {
        padding-top: 24px !important;
        border-top: 1px solid rgba(white, .3) !important;
        border-radius: 0 !important;

        svg {
            width: 124px;

            @include media(md) {
                width: 124px;
            }
        }
    }

    &__Title {
        color: $grey-70;

        #{$root}--Footer & {
            color: white;
            font-weight: $medium;
        }
    }
}

@import 'Styles/Kulturama/includes';

/**
*
* Follow
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Follow.Base.module';

.Follow {
    $root: &;

    text-transform: uppercase;
    background: white;

    &__Wysiwyg {
        a {
            &::after {
                background: palette(black, 16);
            }

            &:hover {
                &::after {
                    background: palette(black);
                }
            }
        }
    }
}

@import 'Styles/Base/includes';

.Footer {
    $root: &;

    position: relative;

    &__Container {
        position: relative;
    }

    &__Wrap {
        @include u-wrap(site);

        position: relative;
        padding-top: 40px;
        padding-bottom: 60px;

        @include media(md) {
            padding-top: 60px;
        }

        @include media(xl) {
            padding-top: 90px;
            padding-bottom: 80px;
        }
    }

    &__Content {
    }

    &__MenuPrimary {
        margin-bottom: 20px;
    }

    &__MenuSecondary {
        margin-bottom: 30px;
        padding-bottom: 30px;

        @include media(sm) {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__Item {
        #{$root}__MenuSecondary & {
            @include media(sm) {
                display: inline-block;
                margin-right: 25px;
            }
        }
    }

    &__Link {
        color: white;
        display: block;
        font-weight: $bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }

        #{$root}__MenuPrimary & {
            padding-top: 3px;
            padding-bottom: 3px;
            font-size: 2.6rem;

            @include media(lg) {
                font-size: 3.2rem;
            }
        }

        #{$root}__MenuSecondary & {
            font-size: 2rem;
            font-weight: $normal;
        }
    }

    &__Info {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        position: relative;
    }

    &__Column {
        @include media(md) {
            flex-basis: 280px;
        }

        &:not(:first-child) {
            margin-left: 60px;

            @include media(md) {
                margin-left: 20px;
            }
        }
    }

    &__Title {
        color: inherit;
    }

    &__Wysiwyg {
        font-size: 1.4rem;
        line-height: 1.44;

        @include media(sm) {
            font-size: 1.6rem;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            color: inherit;
            font-size: 1.6rem;
            margin-bottom: 4px;
        }

        a {
            color: white;

            &:hover {
                text-decoration: underline;

                @media(hover: none) {
                    text-decoration: none;
                }
            }
        }
    }

    &__Logo {
        width: 100%;
        height: 79px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;

        @include media(sm) {
            margin-top: 0;
            margin-right: 0;
        }

        img {
            width: 100%;
            height: 100%;
        }

        &--Top {
            display: none;
        }
    }

    &__EventSidebar {
        display: none;
        margin-top: 40px;
    }
}

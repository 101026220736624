@import 'Styles/Tillskararakademin/includes';

@import './Footer.Base.module.scss';

.Footer {
    $root: &;

    color: palette(black);
    background-color: palette(white);

    &__Wrap {
        display: flex;
        justify-content: center;
        padding-bottom: 40px;

        @include media(md) {
            padding: 60px 20px;
        }

        @include media(xl) {
            padding: 90px 20px;
        }
    }

    &__Menu {
        padding-left: 20px;
        border-left: 3px solid palette(green);
        width: 100%;
    }

    &__Content {
        width: 100%;
    }

    &__MenuSecondary {
        padding-bottom: 0;
    }

    &__Link {
        font-weight: $normal;
        color: palette(black);
    }

    &__Info {
        color: palette(black);
        flex-flow: column;
        align-items: flex-start;

        @include media(sm) {
            flex-flow: row;
        }
    }

    &__Column {
        margin-top: 27px;

        @include media(md) {
            margin-top: 67px;
        }

        &:not(:first-child) {
            margin-left: 0;

            @include media(sm) {
                margin-left: 60px;
            }
        }
    }

    &__Wysiwyg {
        h2 {
            font-size: 1.6rem;
            font-weight: bold;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font-base !important;
        }

        a {
            color: black;
        }
    }

    &__Logo {
        height: 67px;
        width: auto;

        @include media(sm) {
            height: 214px;
            width: auto;
            margin-top: -40px;
        }

        img {
            width: 100%;
            height: 67px;

            @include media(sm) {
                width: auto;
                height: 214px;
            }
        }
    }
}

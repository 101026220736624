@import 'Styles/Dansskolanentre/includes';

@import './Footer.Base.module.scss';

.Footer {
    $root: &;

    background-image: linear-gradient(258.92deg, $tertiary-color -79.5%, $secondary-color 77.63%);

    &__Container {
        color: white;
        overflow: hidden;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            background-size: contain;
        }

        &::before {
            width: 556px;
            height: 683px;
            top: -130px;
            right: -160px;
            background: url('#{$basepath}img/bg-icons/dansskolanentre/swirl-top-right.svg') no-repeat bottom left;

            @include media(sm) {
                top: -50px;
                right: -100px;
            }

            @include media(lg) {
                top: 0;
                right: 0;
            }
        }

        &::after {
            width: 502px;
            height: 372px;
            bottom: -100px;
            left: -200px;
            background: url('#{$basepath}img/bg-icons/dansskolanentre/swirl-bottom-left.svg') no-repeat top right;

            @include media(lg) {
                bottom: -50px;
                left: -100px;
            }

            @include media(lg) {
                bottom: 0;
                left: 0;
            }
        }
    }

    &__Wrap {
        padding-bottom: 24px;
        z-index: 1;

        @include media(md) {
            padding-top: 24px;
        }

        @include media(xl) {
            padding-top: 60px;
            padding-bottom: 24px;
        }
    }

    &__Content {
        @include media(md) {
            display: flex;
            align-items: flex-start;
        }
    }

    &__Menu {
        @include media(sm) {
            flex: 1 0 auto;
            display: flex;
            align-items: flex-start;
        }
    }

    &__MenuColumn {
        @include media(sm) {
            width: 50%;
            padding-right: 20px;
        }
    }

    &__MenuPrimary {

        @include media(sm) {
            margin-bottom: 0;
        }
    }

    &__MenuSecondary {
        padding-bottom: 10px;

        @include media(sm) {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
        }
    }

    &__Item {
        #{$root}__MenuPrimary & {
            &:not(:first-child) {
                margin-top: 10px;
            }
        }

        #{$root}__MenuSecondary & {
            &:not(:first-child) {
                margin-top: 20px;

                @include media(sm) {
                    margin-top: 28px;
                }
            }
        }
    }

    &__Link {
        #{$root}__MenuPrimary & {
            font-size: 2.4rem;

            @include media(lg) {
                font-size: 2.4rem;
            }
        }

        #{$root}__MenuSecondary & {
            font-size: 1.6rem;
            font-weight: $normal;
        }
    }

    &__Info {
        gap: 40px;
        margin-top: 40px;

        @include media(md) {
            gap: 0;
            width: #{percentage(1/3)};
            margin-top: 12px;
        }
    }

    &__Column {
        @include media(md) {
            margin-bottom: 24px;
        }

        &:not(:first-child) {
            margin-left: 0;

            @include media(md) {
                margin-left: 0;
            }
        }
    }

    &__Wysiwyg {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;

                @media(hover: none) {
                    text-decoration: underline;
                }
            }
        }
    }

    &__Logo {
        width: 113px;
        height: 60px;
        margin-bottom: 30px;
        margin-left: 0;

        &--Top {
            display: block;
        }

        &--Bottom {
            display: none;
        }
    }

    &__EventSidebar {
        display: block;
    }
}

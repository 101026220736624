@import 'Styles/Base/includes';

.Follow {
    $root: &;

    padding: 24px 0 48px;
    text-align: center;
    font-weight: $medium;
    font-family: $font-base;
    font-size: 2.4rem;
    line-height: 1.4;
    background: white;

    @include media (sm) {
        padding: 48px 40px 64px;
        font-size: 3.2rem;
    }

    &__Title {
        margin-bottom: 36px;
        color: inherit;

        @include media(sm) {
            margin-bottom: 24px;
        }
    }

    &__Wysiwyg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: inherit;

        @include media (sm) {
            flex-direction: row;
        }

        p {
            @include media (sm) {
                &:last-of-type {
                    margin-left: 10px;
                }
            }
        }

        a {
            position: relative;
            transition: all .2s;

            &::after {
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: -2px;
                transition: all .2s;
            }
        }
    }

    &__Items {
        display: none;
        justify-content: center;
        column-gap: 16px;
    }

    &__Icon {
        position: relative;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: white;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 24px;
            height: 24px;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
}
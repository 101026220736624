@import 'Styles/Base/includes';

.Header {
    $root: &;

    position: relative;
    background-color: white;

    &--Minimal {
        position: absolute;
        left: 50%;
        width: 100%;
        background-color: transparent;
        transform: translateX(-50%);
        z-index: z();
    }

    :global(.MenuMobileActive) & {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: z(MenuMobile__Content);
    }

    &__Content {
        @include u-wrap(site);

        padding-top: 8px;
        min-height: 80px;
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;

        #{$root}--Minimal & {
            min-height: 70px;
        }

        #{$root}--Application & {
            padding-top: 0;
            justify-content: center;
            align-items: center;
        }
    }

    &__Back {
        align-self: flex-start;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 12px;
        margin: auto;
        color: black;
        font-size: 1.2rem;
        font-weight: $medium;
        opacity: .45;
        transition: opacity .3s ease-in-out;

        @include media(sm) {
            left: 20px;
        }

        @include media(lg) {
            left: 30px;
        }

        @include media(xl) {
            left: 60px;
        }

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            top: 2px;
            height: 13px;
            width: 8px;
            margin-right: 8px;
            background: url(#{$basepath}img/bg-icons/base/caret-left.svg) no-repeat center center;
            background-size: contain;
            opacity: 1;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            transition: opacity ease .2s;
            visibility: visible;
            opacity: 0;
        }

        &:hover {
            opacity: .7;

            &::after {
                opacity: 0;
            }
        }
    }

    &__Service {
        @include media(md) {
            border-bottom: 1px solid black;
        }

        #{$root}--Minimal & {
            display: none;
        }
    }

    &__Main {
        @include u-wrap(site);

        position: relative;
        display: flex;
        align-items: center;
        height: 90px;
        border-bottom: 1px solid transparent;
        z-index: z(Header__Container);

        @include media(md) {
            justify-content: space-between;

            #{$root}--Empty & {
                justify-content: center;
                height: 72px;
            }
        }
    }

    &__Logo {
        flex: 0 0 auto;
        display: block;

        img {
            display: block;
        }

        #{$root}--Minimal & {
            margin-left: 8px;

            @include media(md) {
                margin-left: 0;
            }
        }
    }

    &__ButtonLink {
        display: none;
        padding: 16px 30px 18px;
        font-size: 1.5rem;
        line-height: 1;
        text-transform: uppercase;
        border: 1px solid black;
        transition: color .2s, border-color .2s;

        @include media(md) {
            display: block;
        }
    }

    &__Backdrop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(black, .5);
        z-index: z(Header__Backdrop);

        @include media(md) {
            display: block;
        }
    }
}
